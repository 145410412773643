import React from "react";
import StoryPage from "../../components/story-page";

const ThisPage = ({ location }) => {
  const imgUrl = "/images/people/gail-ann-turner.jpeg";
  const fullName = "Gail Ann Turner";
  const zoomUrl = "https://us02web.zoom.us/j/83467558066";
  const zoomPhoneNumber = "(646) 558-8656";
  const zoomMeetingId = "834 6755 8066";
  const birthDate = "September 5, 1942";
  const deathDate = "November 3, 2020";
  const memorialDate = "Saturday November 21, 2020";
  const memorialTime = "1pm EST";
  const inviteText =
    "Please join us in Remembering, celebrating, and mourning Gail Turner with a virtual service honoring her life.";
  const obituary = `Gail A Turner, age 78, passed away Tuesday, November 3 in Virginia Beach, VA. She was born on September 5, 1942 in Dayton, Ohio to Nicholas and Geraldine M Nolan. She attended Julienne High School in Dayton and a couple of years after graduation met her future husband, John Broms...a tall, handsome sailor who whisked her off to Virginia with the Navy.
  \nTogether they had 3 children...Tim of Virginia Beach, Mary Beth of Silver Spring, Maryland, and Michael of San Diego, California. Gail received an Associates Degree from Tidewater Community College while raising her children. She volunteered at the library at Windsor Oaks Elementary School where her children attended which later parlayed into a job becoming the school’s secretary.  After that she worked for the City of Virginia Beach School system as the executive secretary to the head of transportation.
  \nGail retired after 30 years of service to the city. She then moved to Gila Bend, Arizona with her second husband,Jim, in 2004 where they ran an RV park for a couple of years in a town that was purported to be one of the hottest in the nation.  Three years later they moved back to Virginia Beach to be closer to her grandchildren, whom she loved so much.
  \nGail was avid crafter. She was a gifted cross stitcher, knitter, and themed Kleenex box maker. She enjoyed sharing her creations with family and strangers alike. Gail loved people , always up for a party and had long lasting friendships. She often said all she ever wanted was to be a mother and felt this her greatest accomplishment.
  \nShe is predeceased in death by her husband, John Broms...and her 2nd husband, James Turner.
  \nShe is survived by her children, Tim Broms, Mary Beth Etherton, and Michael Broms; 3 grandchildren, Justin, Lauren, and Blaine; Brothers Pat and Nick and Sister, Kathy and many nieces and nephews.`;
  const storiesRecordUrl = "https://stories.afterword.com/fimbobx36";
  const storiesSubmissionsUrl =
    "https://stories.afterword.com/rlglx99w5r08bcm0iyjgaxwvk6nrzrkqifg2aphs";

  return (
    <StoryPage
      location={location}
      imgUrl={imgUrl}
      fullName={fullName}
      zoomUrl={zoomUrl}
      zoomPhoneNumber={zoomPhoneNumber}
      zoomMeetingId={zoomMeetingId}
      birthDate={birthDate}
      deathDate={deathDate}
      customColumnSpacing="1fr 2fr"
      memorialDate={memorialDate}
      memorialTime={memorialTime}
      inviteText={inviteText}
      obituary={obituary}
      storiesRecordUrl={storiesRecordUrl}
      storiesSubmissionsUrl={storiesSubmissionsUrl}
    />
  );
};

export default ThisPage;
